import {
  Show,
  For,
  createResource,
  createSignal,
  Switch,
  Match,
} from "solid-js";
import type { Component } from "solid-js";
import { isPreviewing } from "@builder.io/sdk-solid";
import { Meta } from "@solidjs/meta";
import { useSearchParams } from "@solidjs/router";
import {
  createTimeDifferenceFromNow,
  createCountdownFromNow,
} from "@solid-primitives/date";
import type { Countdown } from "@solid-primitives/date";
import { SiteContext, useSessionContext } from "~/utils/contexts";
import { getBuilderContentByModel } from "~/utils/builder";
import Body from "~/components/Body";
import ProductSlider from "~/components/ProductSlider";
import { Icon } from "solid-heroicons";
import { arrowDownOnSquare, clock } from "solid-heroicons/outline";

export default function Promotions() {
  const { isPartner } = useSessionContext();
  const [_, setParams] = useSearchParams();

  const [content] = createResource(async () => {
    const data = await getBuilderContentByModel("promotion", 9999, 0);
    return data as PromotionData[];
  });

  return (
    <>
      <Body class="relative">
        <Meta name="robots" content="noindex,nofollow" />
        <main class="max-w-4xl mx-auto flex flex-col px-2">
          <h1 class="py-5 text-3xl sm:text-4xl font-bold self-start">
            Current Promotions
          </h1>
          <Show
            when={isPartner()}
            fallback={
              <div class="bg-roma-grey min-h-[40vh] flex items-center justify-center mb-10 rounded-3xl p-2">
                <p class="text-center text-sm sm:text-base">
                  Please{" "}
                  <button
                    class="text-roma-blue"
                    onClick={() => setParams({ signIn: true })}
                  >
                    sign in
                  </button>{" "}
                  to continue.
                </p>
              </div>
            }
          >
            <section>
              <Show
                when={content() && (content() as PromotionData[])?.length > 0}
                fallback={
                  <div class="bg-roma-grey min-h-[40vh] flex items-center justify-center mb-10 rounded-3xl p-2">
                    <p class="text-center text-sm sm:text-base">
                      Nothing to see here right now, check back soon!
                    </p>
                  </div>
                }
              >
                <For each={content()}>
                  {(promo) => (
                    <div class="py-5 sm:py-8 border-b last:border-b-0 first:-mt-10">
                      <PromotionTemplate {...promo.data} />
                    </div>
                  )}
                </For>
              </Show>
            </section>
          </Show>
        </main>
      </Body>
    </>
  );
};

const PromotionTemplate: Component<PromotionData["data"]> = (props) => {
  const formatDateRange = (dateStr1: string, dateStr2: string) => {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    const options = { month: "short" } as const;

    const day1 = date1.getDate();
    const month1 = date1.toLocaleDateString("en-US", options);
    const year1 = date1.getFullYear();

    const day2 = date2.getDate();
    const month2 = date2.toLocaleDateString("en-US", options);
    const year2 = date2.getFullYear();

    // Format the string
    let formattedDateRange = `${month1} ${day1} - `;
    if (year1 !== year2) {
      formattedDateRange += `${month2} ${day2} ${year2}`;
    } else if (month1 !== month2) {
      formattedDateRange += `${month2} ${day2} ${year2}`;
    } else {
      formattedDateRange += `${day2} ${year2}`;
    }

    return formattedDateRange;
  };

  return (
    <div class="shadow-md rounded-2xl sm:rounded-3xl bg-roma-grey p-3 sm:p-6 h-full w-full flex flex-col gap-4">
      <div class="border rounded-[0.75rem] sm:rounded-[1.25rem] overflow-hidden">
        <img src={props.heroImage} alt="" />
      </div>

      <div class="flex justify-between max-md:flex-wrap items-center gap-y-2 py-3 border-b">
        <div class="">
          <h2 class="text-3xl sm:text-5xl font-bold">{props.heading}</h2>
          <Show when={props.subheading}>
            <h3 class="mt-2 sm:text-xl font-bold">{props.subheading}</h3>
          </Show>
        </div>
        <div class="self-start shrink-0 flex flex-col gap-1 px-3 py-2 rounded-lg bg-roma-blue text-white max-sm:text-xs md:ml-4">
          <p>{formatDateRange(props.startDate, props.endDate)}</p>
          <a
            href={props.pdf}
            target="_blank"
            download
            class="flex  items-center hover:underline"
          >
            <span>Download PDF</span>
            <Icon path={arrowDownOnSquare} class="ml-2 w-5 h-5 inline-block" />
          </a>
        </div>
      </div>

      <Show when={props.showCountdown}>
        <SaleCountdown startDate={props.startDate} endDate={props.endDate} />
      </Show>

      <Show when={props.copy && props.copy != ""}>
        <div class="richtext" innerHTML={props.copy} />
      </Show>

      <Show when={props.productSlider && props.productSlider.length > 0}>
        <For each={props.productSlider}>
          {(slider) => (
            <ProductSlider
              title={slider.sliderHeading}
              contentType="List of SKUs"
              productSkus={slider.products}
              limit={20}
            />
          )}
        </For>
      </Show>
    </div>
  );
};

const SaleCountdown: Component<{ startDate: string; endDate: string }> = (
  props
) => {
  const [start] = createSignal(new Date(props.startDate));
  const [end] = createSignal(new Date(props.endDate));
  const [diffToEnd] = createTimeDifferenceFromNow(end);
  const [cdToEnd] = createCountdownFromNow(end);
  const [diffToStart] = createTimeDifferenceFromNow(start);
  const [cdToStart] = createCountdownFromNow(start);

  const fcd = (label: string, val?: number, comma: boolean = false): string => {
    if (!val) return "";
    return `${val.toString()} ${label}${val > 1 ? "s" : ""}${comma ? "," : ""}`;
  };

  const formattedCountdown = (cd: Countdown): string => {
    if (!cd) return "";
    return `${fcd("Day", cd.days, true)} ${fcd("Hour", cd.hours, true)} ${fcd(
      "Minute",
      cd.minutes
    )}`;
  };

  return (
    <div class="flex items-center text-orange-500">
      <Icon path={clock} class="w-7 h-7 mr-2" />
      <Switch>
        <Match when={diffToStart() > 0}>
          <p>Sale starts in {formattedCountdown(cdToStart)}!</p>
        </Match>
        <Match when={diffToEnd() > 0}>
          <p>Sale ends in {formattedCountdown(cdToEnd)}!</p>
        </Match>
        <Match when={diffToEnd() <= 0}>
          <p>Sale has ended!</p>
        </Match>
      </Switch>
    </div>
  );
};

type PromotionData = {
  data: {
    heading: string;
    subheading?: string;
    startDate: string;
    endDate: string;
    showCountdown?: boolean;
    heroImage: string;
    copy: string;
    productSlider?: Slider[];
    pdf?: string;
  };
};

type Slider = {
  sliderHeading?: string;
  products: {
    sku: string;
    type: "Moulding" | "GalleryFrame" | "PhotoFrame";
  }[];
};
